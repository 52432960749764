import React from 'react';
import ColorVersionSelector from './views/ColorVersionSelector';
import { ColorsVersionsProvider } from "./context";

const App = ({title, versions}) => {    
    return (
        <ColorsVersionsProvider versions={versions}>
            <ColorVersionSelector title={title} />
        </ColorsVersionsProvider>
    );
}

export default App;