import { useContext } from "react";
import { ColorsVersionsContext } from "../context";

const useColorsVersionsData = () => {

  const [state, setState] = useContext(ColorsVersionsContext);

  const getColors = () => {
    return state.colors ? state.colors : null;
  }

  const getVersions = () => {
    return state.versions ? state.versions : null;
  }

  const getLoading = () => {
    return state.loading;
  }

  const getSelectedColor = () => {
    return state.selectedColor ? state.selectedColor : null;
  }

  const getSelectedVersion = () => {
    return state.selectedVersion ? state.selectedVersion : null;
  }

  const setSelectedColor = color => {
    setState(prevState => ({
      ...prevState,
      selectedColor: color
    }));
  }

  const setSelectedVersion = version => {
    setState(prevState => ({
      ...prevState,
      selectedVersion: version
    }));
  }


  return {
    getColors,
    getVersions,
    getLoading,
    getSelectedColor,
    getSelectedVersion,
    setSelectedColor,
    setSelectedVersion
  }

}


export default useColorsVersionsData;
