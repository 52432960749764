import React from "react";
import ReactDOM from "react-dom";
import App from './app';

const rootElement = document.getElementById("color-version-selector-root");
if (rootElement) {
    const versions = JSON.parse(rootElement.dataset.versions.replace(/'/g, '"'))
    const title = rootElement.dataset.title;

    ReactDOM.render(<App title={title} versions={versions} />, rootElement);
}