import React from 'react';
import Rotation from 'react-rotation'

import useColorsData from '../hooks/useColorsVersionsData';

const Gallery360 = () => {

    const { getSelectedColor } = useColorsData();
    const selectedColor = getSelectedColor();

    return (
        <div className="slider-360-container">
            <div className="icon-360-container">
                <i className="icon icon-360"></i>
            </div>
            <Rotation cycle scroll={false} reverse>
                {selectedColor.fullcirclegallery && selectedColor.fullcirclegallery.images.map((image, i) => (
                    <div className="item-360" key={`color-${selectedColor.id}-image-${i}`}>
                        <img key={`color-${selectedColor.id}-image-${i}`} src={image.image.url} alt={image.image.alt} />
                    </div>
                ))}
            </Rotation>
        </div>
    )
}

export default Gallery360;