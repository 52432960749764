import React, { useState, useEffect, useRef } from "react";

import Gallery360 from "../components/Gallery360";
import ColorSelector from "../components/ColorSelector";
import VersionSelector from "../components/VersionSelector";

import useColorsData from "../hooks/useColorsVersionsData";

const ColorVersionSelector = ({ title }) => {
    const { getLoading } = useColorsData();

    const loading = getLoading();

    return (
        <>
            <div className='main-container'>
                <h1>{title}</h1>
                <div className='main-container small'>
                    <div className='selector-colors-container'>
                        <div className="selects-container">
                            <VersionSelector />
                            <ColorSelector />
                        </div>
                        {!loading ? (
                            <Gallery360 />
                        ) : (
                            <div className='spinner-container'>
                                <div className='spinner'></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ColorVersionSelector;
