import React, { useState, useEffect } from "react";
import Axios from "axios";

const initialContext = {
    colors: [],
    versions: [],
    selectedVersion: null,
    selectedColor: null,
    loading: true,
};

const ColorsVersionsContext = React.createContext([{}, () => {}]);

const ColorsVersionsProvider = ({ versions, children }) => {
    const [state, setState] = useState(initialContext);
    const defaultSelectedVersion = versions[0]

    const fetchData = (version) => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        Axios.get(`/api/vehicles/360_galleries/${version.version_id}`)
            .then((response) => {
                setState({
                    colors: response.data.colors,
                    selectedColor: response.data.colors.find(color => color.fullcirclegallery),
                    selectedVersion: version,
                    versions: versions
                });
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
            });
    };

    useEffect(() => {
        fetchData(defaultSelectedVersion);
    }, [defaultSelectedVersion]);

    const refetchData = (newVersion) => {
        fetchData(newVersion);
    };

    return (
        <ColorsVersionsContext.Provider value={[state, setState, { refetchData }]}>
            {children}
        </ColorsVersionsContext.Provider>
    );
};

export { ColorsVersionsContext, ColorsVersionsProvider };
