import React, { useEffect, useState, useContext } from "react";

import { ColorsVersionsContext } from "../context";

import useColorsData from "../hooks/useColorsVersionsData";

// Custom Select
const CustomSelect = ({options, placeholder, defaultValue, onChange, disabled, selected}) => {

    const [focused, setFocused] = useState(false);

    const onDocumentClick = (e) => {
       if(e.target.dataset.rule !== 'custom-select') {
           setFocused(false);
       }
    }

    const onItemClick = (item) => {
        if(onChange && typeof onChange === 'function') {
            onChange(item);
        }
        setFocused(false);
    }

    const handleFocus = () => {
        setFocused(!focused);
    }

    const handleLabel = function () {
        if (options && selected) {
            const optionSelected = options.find((item) => item.version_id === selected.version_id)
            if(optionSelected){
                return optionSelected.version_name;
            }
        }
        return placeholder || "Seleccionar";
    }

    useEffect(() => {
        if(focused) document.addEventListener('click', onDocumentClick);
        else document.removeEventListener('click', onDocumentClick);
        return () => document.removeEventListener('click', onDocumentClick);
    }, [focused]);

    return (
        <div className='custom-version-select'>
            {/* Label */}
            <div className="custom-version-select__label" onClick={handleFocus}>
                <span>{handleLabel()}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5">
                    <g fill="none" fillRule="evenodd">
                        <g fill="#191919">
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path d="M181.212 14.5c-.289 0-.548-.164-.658-.414-.11-.25-.049-.536.154-.728l3.068-2.876-3.068-2.877c-.243-.265-.227-.662.037-.909s.688-.263.971-.035l3.576 3.349c.277.261.277.683 0 .944l-3.576 3.348c-.133.126-.314.197-.504.198z" transform="translate(-718 -417) translate(150 119) translate(0 35) translate(74 158) translate(279) translate(0 5) translate(36 92) rotate(90 183 10.5)"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            
            {/* List Items */}
            <ul className={`custom-version-select__list ${focused ? 'focused' : ''}`} data-rule="custom-select">
                {!disabled && options?.length > 0 && options.map((item, key) => (
                    <li
                        className={item.version_id === selected.version_id? 'selected' : ''}
                        onClick={() => onItemClick(item)}
                        key={item.version_id}
                        data-rule="custom-select"
                    >
                        {item.version_name}
                    </li>
                ))}
            </ul>
        </div>
    );
}

// Main Component
const VersionSelector = () => {
    const [colorsState, setState, { refetchData }] = useContext(
        ColorsVersionsContext
    );
    const { getVersions, getSelectedVersion, setSelectedVersion } =
        useColorsData();

    const versions = getVersions();
    const selectedVersion = getSelectedVersion();

    const handleChangeVersion = (version) => {
        if (version.version_id === selectedVersion.version_id) {
            return;
        }
        refetchData(version);
        setSelectedVersion(version);
    };
    return (
        <div className='color-version-selector'>
            <p className='color-version-selector__title'>Elige una versión</p>
            <CustomSelect
                options={versions}
                selected={selectedVersion}
                placeholder={"Selecciona una versión"}
                onChange={handleChangeVersion}
            />
        </div>
    );
};

export default VersionSelector;
